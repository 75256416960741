import { Routes, Route } from 'react-router-dom';
import './App.css';
import {useState} from 'react';
import '@aws-amplify/ui-react/styles.css';
import LoginRegister from './pages/LoginRegister';
import Home from './pages/Home';
import User from './pages/User';
import { Amplify } from 'aws-amplify';
import config from './aws-exports'

Amplify.configure(config);

function App() {
  const [user, setUser] = useState(null);
  return (
    <div className="App hide-scrollbar">
      <Routes>
        <Route path="/" element={<Home user={user} setUser={setUser} />} />
        <Route path="/loginRegister" element={<LoginRegister />} />
        <Route path="/userDashboard" element={<User user={user} setUser={setUser} />} />
      </Routes>
    </div>
  );
}

export default App;
