//1.
import React, {useState} from 'react';
import { Amplify } from 'aws-amplify';
//2.
import awsExports from '../aws-exports';
//3.
import { Authenticator } from '@aws-amplify/ui-react';
//import '@aws-amplify/ui-react/styles.css';
import User from './User';
import { useNavigate, useLocation } from "react-router-dom";

//4.
Amplify.configure(awsExports)

function LoginRegister() {
  const navigate = useNavigate();
  const location = useLocation();
  const originPath = new URLSearchParams(location.search).get('originPath');
  const [authState, setAuthState] = useState("signIn");

  function handleStateChange(state) {
    setAuthState(state);
    if (state === "signedIn") {
      navigate(originPath, { replace: true });
    }
  }

  return (
    <>
      {authState === "signedIn" ? (
        <User />
      ) : (
        <Authenticator
        onStateChange={handleStateChange}
      >
        {({ signOut, user }) => (
          navigate(originPath, {replace: true}))
        }
      </Authenticator>
      )}
    </>
  );
}

export default LoginRegister;