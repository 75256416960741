import React, { useEffect, useState } from "react";
import logo from '../logo.svg';
import { Auth } from 'aws-amplify';
import Header from '../main/Header';
import LoggedUser from '../shared_states/LoggedUser'
import { Link } from 'react-router-dom';

export default function User({user, setUser}) {
  return (
    <>
      <Header user={user} setUser={setUser}/>
      {
        user ? (
          <>
            <h1>User page</h1>
            <img src={logo} className="App-logo" alt="logo" />
            <main>
              <h1>Hello {user.username}</h1>
              <button onClick={Auth.signOut}>Sign out</button>
            </main>
          </>
        ) :
        <p>User not logged in</p>
      }
      <h3>
        <Link to="/">Main page</Link>
      </h3>
    </>
  );
}