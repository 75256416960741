/*global google*/
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useState, useRef, useEffect } from 'react';
import { FaLocationArrow, FaTimes } from 'react-icons/fa';
import { BACKEND_URL } from '../../main/Urls';
import { Auth } from 'aws-amplify';
import {
  Accordion,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner
} from 'react-bootstrap'
import {
  TextField
} from '@mui/material';
import RouteAccordion from "./RouteAccordion";
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

function RouteSelector({routeReference, setRouteReference, user, refreshQuotes}) {
  const google = window.google = window.google ? window.google : {}
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyA4XQiGTUs3AaooRm07y6LDMu8zbL4OXoE',
    libraries: ['places']
  })
  const [showMap, setShowMap] = useState(false);
  const polyLinesArray = []
  const backend_url = BACKEND_URL

  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [computedOrigin, setComputedOrigin] = useState('');
  const [computedDestination, setComputedDestination] = useState('');

  const originRef = useRef(null)
  const destinationRef = useRef(null)
  const [amountOfTrailers, setAmountOfTrailers] = useState('0')
  const [amountOfTrailersIsValid, setAmountOfTrailersIsValid] = useState(false)

  const [tripType, setTripType] = useState("one-way")
  const [trailerOwnership, setTrailerOwnership] = useState("self-owned-trailers")
  const [acceptedTrailers, setAcceptedTrailers] = useState([]);
  const [acceptedTrailersError, setAcceptedTrailersError] = useState(true);

  const [pickUpDate, setPickUpDate] = useState(null);
  const [dropOffDate, setDropOffDate] = useState(null);

  const [returnPickUpDate, setReturnPickUpDate] = useState(null);
  const [returnDropOffDate, setReturnDropOffDate] = useState(null);

  const [currentRoute, setCurrentRoute] = useState(null);
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    // Load values from sessionStorage on mount
    const savedOrigin = sessionStorage.getItem('origin');
    const savedDestination = sessionStorage.getItem('destination');
    if (savedOrigin) {
      setOrigin(savedOrigin);
    }
    if (savedDestination) {
      setDestination(savedDestination);
    }
  }, []);

  useEffect(() => {
    // Load values from sessionStorage on mount
    const savedComputedOrigin = sessionStorage.getItem('computedOrigin');
    const savedComputedDestination = sessionStorage.getItem('computedDestination');
    if (savedComputedOrigin) {
      setComputedOrigin(savedComputedOrigin);
    }
    if (savedComputedDestination) {
      setComputedDestination(savedComputedDestination);
    }
  }, []);

  useEffect(() => {
    if (origin === computedOrigin && destination === computedDestination) {
      calculateRoute();
    }
  }, [origin, destination]);

  useEffect(() => {
    // Save values to sessionStorage when they change
    sessionStorage.setItem('origin', origin);
  }, [origin]);

  useEffect(() => {
    // Save values to sessionStorage when they change
    sessionStorage.setItem('destination', destination);
  }, [destination]);

  useEffect(() => {
    // Save values to sessionStorage when they change
    sessionStorage.setItem('computedOrigin', computedOrigin);
  }, [computedOrigin]);

  useEffect(() => {
    // Save values to sessionStorage when they change
    sessionStorage.setItem('computedDestination', computedDestination);
  }, [computedDestination]);

  function handleOriginChange(e) {
    setOrigin(e.target.value);
  }

  function handleDestinationChange(e) {
    setDestination(e.target.value);
  }

  function handlePlaceOriginChange() {
    setOrigin(originRef.current.value);
  }

  function handlePlaceDestinationChange() {
    setDestination(destinationRef.current.value);
  }

  function handleTripTypeChange(value) {
    setTripType(value);
  }

  function handleTrailerOwnershipChange(value) {
    setTrailerOwnership(value)
  }

  function handleAmountOfTrailersChange(event) {
    const newValue = parseInt(event.target.value);

    if (isNaN(newValue) || newValue < 1) {
      // Revert to previous value if new value is not valid
      setAmountOfTrailersIsValid(false);
      setAmountOfTrailers(event.target.value);
    } else {
      // Update current and previous values
      setAmountOfTrailersIsValid(true);
      setAmountOfTrailers(event.target.value);
    }
  }

  function handleAcceptedTrailersChange(e) {
    const value = e.target.value;
    if (e.target.checked) {
      setAcceptedTrailers([...acceptedTrailers, value]);
      setAcceptedTrailersError(false);
    } else {
      if (acceptedTrailers.length === 1) {
        setAcceptedTrailersError(true);
      } else {
        setAcceptedTrailersError(false);
      }
      setAcceptedTrailers(acceptedTrailers.filter((val) => val !== value));
    }
  };

  function handlePickUpDateChange(date) {
    setPickUpDate(date);
    if (dropOffDate && date > dropOffDate) {
      setDropOffDate(null);
    }
  }

  function handleDropOffDateChange(date) {
    if (!pickUpDate || date > pickUpDate) {
      setDropOffDate(date);
    }
  }

  function handleReturnPickUpDateChange(date) {
    setReturnPickUpDate(date);
    if (returnDropOffDate && date > returnDropOffDate) {
      setReturnDropOffDate(null);
    }
  }

  function handleReturnDropOffDateChange(date) {
    if (!returnPickUpDate || date > returnPickUpDate) {
      setReturnDropOffDate(date);
    }
  }

  if (originRef.current) {
    originRef.current.value = origin;
  }

  if (destinationRef.current) {
    destinationRef.current.value = destination;
  }

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" variant="primary" />
      </div>
    )
  }

  async function calculateRoute() {
    if (!originRef.current || !destinationRef.current || originRef.current.value === '' || destinationRef.current.value === '') {
      return
    }
    const currentUser = await Auth.currentAuthenticatedUser()
      .catch(err => { });
    let response = null;
    if (currentUser) {
      const jwtToken = await Auth.currentSession().then(session => session.getIdToken().getJwtToken());
      response = await fetch(backend_url + "v1/routes/intermodal_routes", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({
          origin: originRef.current.value,
          destination: destinationRef.current.value
        }),
      });
    } else {
      response = await fetch(backend_url + "v1/routes/intermodal_routes", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          origin: originRef.current.value,
          destination: destinationRef.current.value
        }),
      });
    }

    const jsonResponse = await response.json();
    const currentResponse = jsonResponse.data[0]
    setRoutes(jsonResponse.data)
      setCurrentRoute(currentResponse)
    setRouteReference(currentResponse.reference)

    setComputedOrigin(originRef.current.value)
    setComputedDestination(destinationRef.current.value)

    setShowMap(true);
  }

  return (
    <>
      <Container className="position-relative d-flex flex-column align-items-center justify-content-center pt-5" style={{ height: '100%' }}>
        <Form className="border rounded">
          <Row className="w-100 align-items-stretch">
            <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2">
              <Form.Group controlId="formGroupInput1">
                <Form.Label className="text-left">Origin</Form.Label>
                <Autocomplete
                  onPlaceChanged={handlePlaceOriginChange}
                >
                  <Form.Control type="text" placeholder="" ref={originRef} onChange={handleOriginChange} />
                  {/*
                <TextField
              id="destinationField"
            label="Destination"
            inputRef={destinationRef}
            onChange={handleDestinationChange}
            variant="outlined"
          />*/}
                </Autocomplete>
              </Form.Group>
            </Col>
            <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2">
              <Form.Group htmlFor="round-trip-radio">
                <Form.Label className="text-left">Destination</Form.Label>
                <Autocomplete
                  onPlaceChanged={handlePlaceDestinationChange}
                >
                  <Form.Control type="text" placeholder="" ref={destinationRef} onChange={handleDestinationChange} />
                  {/*<TextField
                id="originField"
            label="Origin"
            inputRef={originRef}
            onChange={handleOriginChange}
            variant="outlined"
          />*/}
                </Autocomplete>
              </Form.Group>
            </Col>
            <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2">
              <Form.Group>
                <Form.Label htmlFor="round-trip-radio" className="text-left">Trip type</Form.Label>
                <br />
                <ButtonGroup>
                  <Button
                    variant={tripType === "one-way" ? "primary" : "outline-primary"}
                    onClick={() => handleTripTypeChange("one-way")}
                  >
                    One way
                  </Button>
                  <Button
                    variant={tripType === "round-trip" ? "primary" : "outline-primary"}
                    onClick={() => handleTripTypeChange("round-trip")}
                  >
                    Round trip
                  </Button>
                </ButtonGroup>
              </Form.Group>
            </Col>
            <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2">
              <Form.Group>
                <Form.Label htmlFor="round-trip-radio" className="text-left">Amount of trailers</Form.Label>
                <Form.Control type="text" placeholder="" value={amountOfTrailers} onChange={handleAmountOfTrailersChange} isInvalid={!amountOfTrailersIsValid} />
                {!amountOfTrailersIsValid && <Form.Control.Feedback type="invalid">Please enter a positive integer.</Form.Control.Feedback>}
              </Form.Group>
            </Col>
            <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2">
              <Form.Group>
                <Form.Label htmlFor="trailer-ownership-radio" className="text-left">Trailer ownership</Form.Label>
                <br />
                <ButtonGroup>
                  <Button
                    variant={trailerOwnership === "self-owned-trailers" ? "primary" : "outline-primary"}
                    onClick={() => handleTrailerOwnershipChange("self-owned-trailers")}
                  >
                    Self owned trailers
                  </Button>
                  <Button
                    variant={trailerOwnership === "required" ? "primary" : "outline-primary"}
                    onClick={() => handleTrailerOwnershipChange("required")}
                  >
                    Required
                  </Button>
                </ButtonGroup>
              </Form.Group>
            </Col>
            <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2 h-100">
              <Form.Group controlId="formGroupCheckbox">
                <Form.Label>Accepted trailers</Form.Label>
                <br />
                <Form.Check
                  inline
                  label="Reefer"
                  value="reefer"
                  id="reefer"
                  checked={acceptedTrailers.includes('reefer')}
                  onChange={handleAcceptedTrailersChange}
                />
                <Form.Check
                  inline
                  label="Dry box"
                  value="dry_box"
                  id="dry_box"
                  checked={acceptedTrailers.includes('dry_box')}
                  onChange={handleAcceptedTrailersChange}
                />
                <Form.Check
                  inline
                  label="Curtain"
                  value="curtain"
                  id="curtain"
                  checked={acceptedTrailers.includes('curtain')}
                  onChange={handleAcceptedTrailersChange}
                />
                <Form.Check
                  inline
                  label="Tanker"
                  value="tanker"
                  id="tanker"
                  checked={acceptedTrailers.includes('tanker')}
                  onChange={handleAcceptedTrailersChange}
                />
                <Form.Check
                  inline
                  label="Container"
                  value="container"
                  id="container"
                  checked={acceptedTrailers.includes('container')}
                  onChange={handleAcceptedTrailersChange}
                />
              </Form.Group>
              {acceptedTrailersError && (
                <Form.Control.Feedback type="invalid" className="d-block">Please select at least one option.</Form.Control.Feedback>
              )}
            </Col>

            <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2">
              <Form.Group>

                <Form.Label>Pick-up Date:</Form.Label>
                <Datetime value={pickUpDate} onChange={handlePickUpDateChange} timeFormat="HH:mm" />

              </Form.Group>

            </Col>
            <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2">
              <Form.Group>
                <Form.Label>Drop-off Date</Form.Label>
                <Datetime value={dropOffDate} onChange={handleDropOffDateChange} timeFormat="HH:mm" />
              </Form.Group>
            </Col>
            <Col xl={8} md={4} sm={0} className="text-left pt-3 pb-2">
            </Col>

            {tripType === 'round-trip' &&
              <>
                <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2">
                  <Form.Group>

                    <Form.Label>Return pick-up Date:</Form.Label>
                    <Datetime value={returnPickUpDate} onChange={handleReturnPickUpDateChange} timeFormat="HH:mm" />

                  </Form.Group>

                </Col>
                <Col xl={2} md={4} sm={12} className="text-left pt-3 pb-2">
                  <Form.Group>
                    <Form.Label>Return drop-off Date</Form.Label>
                    <Datetime value={returnDropOffDate} onChange={handleReturnDropOffDateChange} timeFormat="HH:mm" />
                  </Form.Group>
                </Col>
                <Col xl={8} md={4} sm={0} className="text-left pt-3 pb-2">
                </Col>
              </>
            }


          </Row>
        </Form>
        <Row className="w-100 align-items-stretch justify-content-center">
          <Col xl={6} md={12}></Col>
          <Col xl={6} md={12}>
            <div className="d-flex justify-content-end mt-3 mb-3 mr-2 w-100 row">
              <Button colorscheme='pink' type='submit' onClick={calculateRoute} variant="success" disabled={!origin || !destination} title={(!origin || !destination) ? 'Please enter an origin and a destination' : ''}>
                Calculate Route
              </Button>
            </div>
          </Col>

          {showMap && routes.map((route, index) => (
  <RouteAccordion
    key={index}
    showMap={showMap}
    route={route}
    origin={origin}
    destination={destination}
    index={index}
    user={user}
    refreshQuotes={refreshQuotes}
  />
))}

        
        </Row>
      </Container>
    </>
  )

};

export default RouteSelector