import { checkUser } from "../functions/Authentication";
import React, { useEffect, useState } from 'react';
import logo from '../logo.svg';
import { Link } from 'react-router-dom';
import Header from '../main/Header';
import SideBar from '../main/SideBar';
import { useLocation } from "react-router-dom";
import LoginRegister from "./LoginRegister";
import RouteSelectorPage from "./RouteSelector";
import RouteSelector from "../components/routes/RouteSelector"
import {
  Tab,
  Nav
} from 'react-bootstrap';
import '../styles/home.css'

import { ProSidebarProvider } from "react-pro-sidebar";

export default function Home({ user, setUser }) {

  const [selectedTab, setSelectedTab] = useState(
    sessionStorage.getItem('selectedTab') || 'route'
  );

  useEffect(() => {
    sessionStorage.setItem('selectedTab', selectedTab);
  }, [selectedTab]);

  function refreshQuotes() {

  }

  function getQuote() {
    refreshQuotes()
    setSelectedTab("pending-quotes")
  }
  
  return (
    <>
      <Header user={user} setUser={setUser} />
      <div className="main-container">
        <div className="sidebar">
        <ProSidebarProvider>
          <SideBar user={user}/>
          </ProSidebarProvider>
      </div>
      <div className="content">
      {!user ? (<RouteSelector routeReference={null} setRouteReference={() => {}} />) : (
        <>
      <Tab.Container activeKey={selectedTab} onSelect={setSelectedTab}>
        <Nav variant="tabs" fill className="flex-column flex-sm-row">
          <Nav.Item>
            <Nav.Link eventKey="route">Route Selector</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="pending-quotes">Pending quotes</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="bookings">Bookings</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="route">
          <RouteSelectorPage user={user} setUser={setUser} getQuote={getQuote} refreshQuotes={refreshQuotes} />
          </Tab.Pane>
          <Tab.Pane eventKey="pending-quotes">
            <p>
              <Link to="/userDashboard">Pending quotes</Link>
            </p>
          </Tab.Pane>
          <Tab.Pane eventKey="bookings">
            <p>
              <Link to="/userDashboard">Bookings</Link>
            </p>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      </>
      )}
        </div>
      </div>

    </>
  );
}
