import { Auth } from 'aws-amplify';

export async function checkUser(setUser) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch (error) {
      setUser(null);
    }
  }

  