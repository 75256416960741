import React, { useEffect, useState } from "react";
import logo from '../logo.svg';
import { Auth } from 'aws-amplify';
import Header from '../main/Header';
import LoggedUser from '../shared_states/LoggedUser'
import { Link } from 'react-router-dom';
import { 
    Button,
    ButtonGroup,
    Col,
    Container,
    Row
} from 'react-bootstrap';
import RouteSelector from "../components/routes/RouteSelector";

export default function RouteSelectorPage({user, setUser, getQuote, refreshQuotes}) {
  
    const [routeReference, setRouteReference] = useState(
        sessionStorage.getItem('routeReference')
    );
    
    function handleGetQuote(){
        getQuote()
    }

    useEffect(() => {
        sessionStorage.setItem('routeReference', routeReference)
      }, [routeReference]);

    return (
        <>
            <RouteSelector routeReference={routeReference} setRouteReference={setRouteReference} user={user} refreshQuotes={refreshQuotes} />
            {user && routeReference &&
                            <Container className="my-3 w-100">
                            <Row>
                                <Col xl={12}>
                                    <Button
                                        variant="info"
                                        onClick={handleGetQuote}
                                        className="btn-block w-100"
                                    >
                                        Get quote for all intermodal alternatives
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
            }
        </>
    );
}