import { checkUser } from "../functions/Authentication";
import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Link, useLocation } from 'react-router-dom';
import '../styles/navbar.css'
import Button from 'react-bootstrap/Button';

export default function Header({user, setUser}) {

    const location = useLocation();
    const [isNavExpanded, setIsNavExpanded] = useState(false)

    useEffect(() => {
        checkUser(setUser);
      }, []);

    return (
        <>
    <nav className="navigation">
      <a href="/" className="brand-name">
        TheGreenMiddleMile
      </a>
      <button className="hamburger"
              onClick={() => {
                setIsNavExpanded(!isNavExpanded);
              }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="white"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={
          isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
        }
        style={{zIndex: 999}}
      >
        <ul className="pt-3">
          {
          /*
        <li>
            <a href="/home">Home</a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
          */
          }
          <li>
          {
            user ? (
                <>
                {'Welcome '}
                {
                    user.attributes.email
                }{' '}
                <Button variant="primary" onClick={async () => {
                    await Auth.signOut();
                    window.location.reload()
                }}>Sign out</Button>{' '}
                </>
            ) : (
                <Button variant="primary" onClick={() => window.location.href=`/loginRegister?originPath=${location.pathname}`}
                >Login</Button>
            )
        }
        </li>
        </ul>
      </div>
    </nav>
        </>
    )
  }
