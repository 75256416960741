/*global google*/
import {
    useJsApiLoader,
    GoogleMap,
    Marker,
    Autocomplete,
    DirectionsRenderer,
  } from "@react-google-maps/api";
import { useState, useRef, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { BACKEND_URL } from '../../main/Urls';
import {
    Accordion,
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    Form,
    Row,
    Spinner
  } from 'react-bootstrap'

function RouteAccordion({showMap, route, origin, destination, index, user, refreshQuotes}) {
    const google = window.google = window.google ? window.google : {}
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyA4XQiGTUs3AaooRm07y6LDMu8zbL4OXoE',
        libraries: ['places']
      })
    const [map, setMap] = useState(/** @type google.maps.Map */ null)
    let polyLinesArray = []
    const backend_url = BACKEND_URL

    const [center, setCenter] = useState({ lat: 39.4747, lng: -0.3586 })
    const [zoom, setZoom] = useState(15)
    const [bounds, setBounds] = useState(null);
    const [polylines, setPolylines] = useState([]);
    const [title, setTitle] = useState('');
    const [roadDistance, setRoadDistance] = useState(null);

    const [getQuoteButtonClicked, setGetQuoteButtonClicked] = useState(false);
    const [discardQuoteButtonClicked, setDiscardQuoteButtonClicked] = useState(false);
    const [routeQuoted, setRouteQuoted] = useState(false);

    useEffect(() => {
      computeMapElements(index);
    }, [origin, destination]);

    useEffect(() => {
      setGetQuoteButtonClicked(false)
    }, [roadDistance])

    async function getCoordinates(address) {
      const geocoder = new google.maps.Geocoder();
      return new Promise((resolve, reject) => {
        geocoder.geocode({ address: address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const location = results[0].geometry.location;
            resolve({ lat: location.lat(), lng: location.lng() });
          } else {
            reject(new Error('Geocoding failed. Please check your address.'));
          }
        });
      });
    }

    async function addStraightLineToMap(origin, destination, currentMap) {
      try {
        const originCoords = await getCoordinates(origin);
        const destinationCoords = await getCoordinates(destination);
    
        const straightPath = [originCoords, destinationCoords];
    
        var newPolyline = new google.maps.Polyline({
          path: straightPath,
          strokeColor: 'green',
          strokeOpacity: 1.0,
          strokeWeight: 5,
        });
    
        newPolyline.setMap(currentMap);
        polyLinesArray.push(newPolyline);
      } catch (error) {
        console.error(error);
      }
    }

    async function addDrivingRouteToMap(origin, destination, currentMap) {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService();
        const results = await directionsService.route({
          origin: origin,
          destination: destination,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING,
        });
        var newPolyline = new google.maps.Polyline({
          path: results.routes[0].overview_path,
          strokeColor: 'red',
          strokeOpacity: 1.0,
          strokeWeight: 5,
        })
        newPolyline.setMap(currentMap)
        polyLinesArray.push(newPolyline)
        return results
      }

    
      async function addTransitRouteToMap(origin, destination, currentMap) {
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService();
        const results = await directionsService.route({
          origin: origin,
          destination: destination,
          // eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.TRANSIT,
          transitOptions: {
            modes: ["TRAIN"]
          }
        });
        var newPolyline = new google.maps.Polyline({
          path: results.routes[0].overview_path,
          strokeColor: 'green',
          strokeOpacity: 1.0,
          strokeWeight: 5,
        })
        newPolyline.setMap(currentMap)
        
        polyLinesArray.push(newPolyline)
        return results
      }

    async function computeMapElements(index){
      for (let i = 0; i < polyLinesArray.length; i++) {
        polyLinesArray[i].setMap(null);
      }
      polyLinesArray = [];
        if(route.rail_journey){
        const res1 = await addDrivingRouteToMap(
            origin,
            route.rail_journey.origin.search_name,
            map
          )
        const res2 = await addStraightLineToMap(
            route.rail_journey.origin.search_name,
            route.rail_journey.destination.search_name,
            map
          )
        const res3 = await addDrivingRouteToMap(
            route.rail_journey.destination.search_name,
            destination,
            map
          )
          setTitle('Intermodal alternative ' + (index + 1));
          let totalDistance = res1.routes[0].legs[0].distance.value + res3.routes[0].legs[0].distance.value
          setRoadDistance((totalDistance / 1000))
        } else {
            const res1 = await addDrivingRouteToMap(
                origin,
                destination,
                map
              )
            setTitle('Road route')
            let totalDistance = res1.routes[0].legs[0].distance.value
            setRoadDistance((totalDistance / 1000))
        }
        setPolylines(polyLinesArray);
        setBounds(new google.maps.LatLngBounds());
        polyLinesArray.forEach((polyline) => {
          const path = polyline.getPath();
          path.forEach((latLng) => {
            bounds.extend(latLng);
          });
        });
        map.fitBounds(bounds);
    }

    async function askForQuote(){
      setGetQuoteButtonClicked(true);
      const jwtToken = await Auth.currentSession().then(session => session.getIdToken().getJwtToken());
      let response = await fetch(backend_url + "v1/routes/intermodal_routes/" + route.reference + "/", {
        method: "PATCH",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({
          "asked_for": true,
        }),
      });
      if (response.status === 200) {
        setRouteQuoted(true);
        setGetQuoteButtonClicked(false);
      }
    }

    async function discardQuote(){
      setDiscardQuoteButtonClicked(true);
      const jwtToken = await Auth.currentSession().then(session => session.getIdToken().getJwtToken());
      let response = await fetch(backend_url + "v1/routes/intermodal_routes/" + route.reference + "/", {
        method: "PATCH",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({
          "asked_for": false,
        }),
      });
      if (response.status === 200) {
        setRouteQuoted(false);
        setDiscardQuoteButtonClicked(false);
      }
    }

    useEffect(() => {
        if (map) {
            computeMapElements(index);
        }
      }, [map]);

    return (
        <Col xl={12}>
        <Container
        className={`d-flex ${showMap ? '' : 'd-none'
          }`} style={{ paddingTop: '20px', paddingBottom: '20px' }}
      >
        <Accordion className="w-100">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{display: 'block'}}>
                {title}
                </div>
                </div>
                
                <div style={{display: 'flex', alignItems: 'center'}}>
                <div>
                  Road distance: {roadDistance} km
                </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                {!routeQuoted && user && route.rail_journey &&
                  <Button variant="primary" className="ms-auto" style={{marginRight: '20px'}}
                  onClick={(e) => {
                    askForQuote();
                    refreshQuotes();
                    e.stopPropagation();
                    // Add your button click logic here
                  }}
                  disabled={getQuoteButtonClicked}
                >Get quote</Button>
                }
                {routeQuoted && user && route.rail_journey &&
                  <Button variant="danger" className="ms-auto" style={{marginRight: '20px'}}
                  onClick={(e) => {
                    discardQuote();
                    refreshQuotes();
                    e.stopPropagation();
                    // Add your button click logic here
                  }}
                  disabled={discardQuoteButtonClicked}
                >Discard quote</Button>
                }
                </div>
                
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <Container
                  className="d-flex justify-content-center"
                  style={{
                    height: '350px',
                    width: '100%',
                  }}
                >
                  <GoogleMap
                    key={bounds ? bounds.toUrlValue() : 'default-key'}
                    center={center}
                    zoom={zoom}
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    options={{
                      streetViewControl: false,
                      fullscreenControl: false,
                    }}
                    onLoad={(map) => {
                      setMap(map);
                      setBounds(new google.maps.LatLngBounds());
                    }}
                ></GoogleMap>
                </Container>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </Container>
          </Col>
    )
}






export default RouteAccordion